import { NodeController } from "./node-controller";
import {ItemReorderEventDetail} from "@ionic/angular";

export class GlobalStores {
  public static lastTouched: number = 0;

  public static accountController: NodeController;
  public static categoryController: NodeController;
  public static transactionController: NodeController;
  public static budgetEntryController: NodeController;

  public static getCategoryById(id: string): any {
    return this.categoryController.getEntityById(id);
  }

  public static getCategoryByName(id: string): any {
    return this.categoryController.getEntityByName(id);
  }

  public static getAccountById(id: string): any {
    return this.accountController.getEntityById(id);
  }

  public static getAccountByName(name: string): any {
    return this.accountController.getEntityByName(name);
  }

  public static reorderCategories(ev: CustomEvent<ItemReorderEventDetail>) {
    ev.detail.complete(GlobalStores.categoryController.entities);

    let elements = GlobalStores.categoryController.entities;
    for (let i = 0; i < elements.length; i++) {
      elements[i].sortingPosition = i;
      GlobalStores.categoryController.updateEntity({
          id: elements[i].id,
          name: elements[i].name,
          description: elements[i].description,
          sortingPosition: elements[i].sortingPosition,
          hidden: elements[i].hidden,
          _version: elements[i]._version
        },
        elements[i].id, elements[i].name, '', '');
    }

    GlobalStores.categoryController.sortBy('sortingPosition', 'asc');
  }
}
