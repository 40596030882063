import { Component } from '@angular/core';
import { signIn, type SignInInput } from 'aws-amplify/auth';
import { AlertController, Platform, ToastController } from "@ionic/angular";
import { GlobalStores } from "./global-stores";
import { APIService } from './API.service';
import { NodeController } from "./node-controller";
import { Utils } from "./utils";
import { TransactionsPage } from "./transactions_tab/transactions.page";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private apiService: APIService,
              private platform: Platform,
              private alertController: AlertController,
              private toastController: ToastController) {
    GlobalStores.accountController = new NodeController(
      true,
      'name',
      'name',
      'asc',
      NodeController.sortArray,
      platform,
      toastController,
      alertController,
      apiService.OnCreateAccountListener,
      evt => evt.value.data.onCreateAccount,
      apiService.CreateAccount,
      apiService.OnDeleteAccountListener,
      evt => evt.value.data.onDeleteAccount,
      apiService.DeleteAccount,
      apiService.OnUpdateAccountListener,
      evt => evt.value.data.onUpdateAccount,
      apiService.UpdateAccount,
      apiService.ListAccounts);

    GlobalStores.categoryController = new NodeController(
      true,
      'name',
      'sortingPosition',
      'asc',
      NodeController.sortArray,
      platform,
      toastController,
      alertController,
      apiService.OnCreateCategoryListener,
      evt => evt.value.data.onCreateCategory,
      apiService.CreateCategory,
      apiService.OnDeleteCategoryListener,
      evt => evt.value.data.onDeleteCategory,
      apiService.DeleteCategory,
      apiService.OnUpdateCategoryListener,
      evt => evt.value.data.onUpdateCategory,
      apiService.UpdateCategory,
      apiService.ListCategories);

    GlobalStores.transactionController = new NodeController(
      false,
      'transactionDate',
      'transactionDate',
      'desc',
      NodeController.sortArray,
      platform,
      toastController,
      alertController,
      apiService.OnCreateTransactionListener,
      evt => evt.value.data.onCreateTransaction,
      apiService.CreateTransaction,
      apiService.OnDeleteTransactionListener,
      evt => evt.value.data.onDeleteTransaction,
      apiService.DeleteTransaction,
      apiService.OnUpdateTransactionListener,
      evt => evt.value.data.onUpdateTransaction,
      apiService.UpdateTransaction,
      apiService.ListTransactions);

    GlobalStores.budgetEntryController = new NodeController(
      true,
      'monthlyBudgetYearAndMonth',
      'monthlyBudgetYearAndMonth',
      'desc',
      NodeController.sortArray,
      platform,
      toastController,
      alertController,
      apiService.OnCreateMonthlyBudgetEntryListener,
      evt => evt.value.data.onCreateMonthlyBudgetEntry,
      apiService.CreateMonthlyBudgetEntry,
      apiService.OnDeleteMonthlyBudgetEntryListener,
      evt => evt.value.data.onDeleteMonthlyBudgetEntry,
      apiService.DeleteMonthlyBudgetEntry,
      apiService.OnUpdateMonthlyBudgetEntryListener,
      evt => evt.value.data.onUpdateMonthlyBudgetEntry,
      apiService.UpdateMonthlyBudgetEntry,
      apiService.ListMonthlyBudgetEntries);
  }

  services = {
    aToastController: this.toastController,

    async handleSignIn({ username, password }: SignInInput) {
      signIn({ username, password }).then(success => {
        window.location.href = '';
      }, error => {
        console.log('Error in handling sign-in');
        this.aToastController.create({
          message: 'Incorrect username or password.',
          duration: 2000,
          color: 'danger',
          position: 'top'
        }).then(toast => {
          toast.present();
        });
      });
    },
  };
}
