import {Platform, PopoverController, ToastController} from "@ionic/angular";
import {Direction} from "./API.service";

export class Utils {
  static months = [['January', 1],
    ['February', 2],
    ['March', 3],
    ['April', 4],
    ['May', 5],
    ['June', 6],
    ['July', 7],
    ['August', 8],
    ['September', 9],
    ['October', 10],
    ['November', 11],
    ['December', 12]];

  static async presentToast(toastController: ToastController, messageText: string, messageColor: string) {
    const toast = await toastController.create({
      message: messageText,
      duration: 2000,
      color: messageColor,
      position: 'top'
    });
    await toast.present();
  }

  static selectTriggerButton(platform: Platform) {
    if (platform.is('desktop') || platform.is('electron')) {
      return 'add-account-button-desktop';
    }
    return 'add-account-button-mobile';
  }

  static formatAmountOnPage(amountInCents: number): string {
    return (amountInCents / 100).toFixed(2);
  }

  static nullOrEmpty(value: any): boolean {
    return value == null || value == '';
  }

  static isBoolean(value: string): boolean {
    return value == 'true' || value == 'false';
  }

  static stringToBoolean(value: string): boolean {
    return value == 'true';
  }

  static stripQuotedString(value: string): string {
    if (value.startsWith('"') && value.endsWith('"')) {
      return value.slice(1, -1);
    } else {
      return value;
    }
  }

  static isNumeric(str: any) {
    //if (typeof str != "string") return false // we only process strings!
    return !Utils.nullOrEmpty(str) && // cannot be null or empty
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  static isInteger(value) {
    return /^-?\d+$/.test(value);
  }

  static isPositiveInteger(value) {
    return /^\d+$/.test(value);
  }

  static showFullDisplay(platform) {
    if (platform.is('desktop') || platform.is('electron')) {
      if (platform.width() > 1300) {
        return true;
      }
      return false;
    }
    return false;
  }

  static popoverFocus($event, elementClass, inShadowRoot = false, shadowClass = null) {
    let focusableElements;
    if (inShadowRoot) {
      const rootList = $event.target.getElementsByClassName(elementClass);
      if (rootList == null || rootList.length == 0) return;

      const root = rootList[0];
      const shadowRootChildren = root.shadowRoot.children;
      for (let i = 0; i < shadowRootChildren.length; i++) {
        focusableElements = shadowRootChildren[i].getElementsByClassName(shadowClass);
        if (focusableElements.length > 0) break;
      }
    } else {
      focusableElements = $event.target.getElementsByClassName(elementClass);
    }

    let mainFocus = null;
    if (focusableElements != null && focusableElements.length > 0) {
      mainFocus = focusableElements[0];
    }

    if (mainFocus != null) {
      new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
        try {
          mainFocus.focus();
        } catch (e) {
          // nothing to do
        }
        try {
          mainFocus.setFocus();
        } catch (e) {
          // nothing to do
        }
      });
    }
  }

  static isValidDirection(direction: string): boolean {
    if (direction == Direction.EXPENSE) return true;
    // noinspection RedundantIfStatementJS
    if (direction == Direction.INCOME) return true;

    return false;
  }

  static isValidUUID(value: string) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(value);
  }

  static formatDirection(direction: Direction): string {
    if (direction == 'INCOME') {
      return "Income";
    }

    return "Expense";
  }

  static nullOrFalse(x) {
    return x == null || x == false;
  }

  static CSVtoArray(text) {
    const clonedText = (' ' + text).slice(1);

    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(clonedText)) return null;
    let a = [];                     // Initialize array to receive values.
    clonedText.replace(re_value, // "Walk" the string using replace with callback.
      function(m0, m1, m2, m3) {
        // Remove backslash from \' in single quoted values.
        if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
        // Remove backslash from \" in double quoted values.
        else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
        else if (m3 !== undefined) a.push(m3);
        return ''; // Return empty string.
      }
    );
    // Handle special case of empty last value.
    if (/,\s*$/.test(clonedText)) a.push('');
    return a;
  };

  // Year and month
  static formatDate(value: string) {
    if (this.nullOrEmpty(value)) return value;
    return value.substring(0, 10);
  }

  static formatDateAndNext(value: string, popoverController: PopoverController, nextFocus: any = null): string {
    popoverController.dismiss().then(v => {
      if (nextFocus != null) {
        nextFocus.focus();
      }
    });
    return value.substring(0, 10);
  }

  static isDateValid(dateString: string) {
    if (Utils.nullOrEmpty(dateString)) return false;

    const dateParts = dateString.split('-');
    if (dateParts.length != 3) return false;
    if (dateParts[0].length != 4 || !Utils.isPositiveInteger(dateParts[0])) return false;
    if (dateParts[1].length != 2 || !Utils.isPositiveInteger(dateParts[1])) return false;
    if (dateParts[2].length != 2 || !Utils.isPositiveInteger(dateParts[2])) return false;
    // noinspection RedundantIfStatementJS
    if (isNaN(new Date(dateString).valueOf())) return false;
    return true;
  }

  static isYearAndMonthValid(yearAndMonthString: string) {
    if (Utils.nullOrEmpty(yearAndMonthString)) return false;

    const dateParts = yearAndMonthString.split('-');
    if (dateParts.length != 2) return false;
    if (dateParts[0].length != 4 || !Utils.isPositiveInteger(dateParts[0])) return false;
    if (dateParts[1].length != 2 || !Utils.isPositiveInteger(dateParts[1])) return false;
    // noinspection RedundantIfStatementJS
    if (Number(dateParts[1]) < 1 || Number(dateParts[1]) > 12) return false;

    return true;
  }

  static isTimeValid(timeString: string) {
    const timeParts = timeString.split('.');
    const actualTime = timeParts[0];
    const millisAndTZ = timeParts[1];

    const actualTimeParts = actualTime[0].split(':');
    actualTimeParts.forEach(v => {
      if (v.length != 2 || !Utils.isPositiveInteger(v)) return false;
    });

    // noinspection RedundantIfStatementJS
    if (millisAndTZ.length != 4 || millisAndTZ[3] != 'Z' || !Utils.isPositiveInteger(millisAndTZ.slice(0, 3))) return false;

    return true;
  }

  static isDateTimeValid(dateTimeString: string) {
    if (Utils.nullOrEmpty(dateTimeString)) return false;

    const dateTimeParts = dateTimeString.split('T');
    if (!Utils.isDateValid(dateTimeParts[0])) return false;
    // noinspection RedundantIfStatementJS
    if (!Utils.isTimeValid(dateTimeParts[1])) return false;

    return true;
  }

  static getFormattedMonth(month: number): string {
    return String(month).padStart(2, '0');
  }

  static getYearAndMonthPrefix(yearAndMonth: Array<number>): string {
    return String(yearAndMonth[0]) + '-' + Utils.getFormattedMonth(yearAndMonth[1]);
  }

  static formatYearAndMonth(yearAndMonth: Array<number>): string {
    const monthNames = Utils.months.map(v => v[0]);
    return monthNames[yearAndMonth[1] - 1] + ' ' + yearAndMonth[0];
  }

  static getYearAndMonthFromString(yearAndMonth: string): Array<number> {
    const year = parseInt(yearAndMonth.substring(0, 4));
    const month = parseInt(yearAndMonth. substring(5, 7));

    return new Array<number>(year, month);
  }

  static shiftXMonthsFrom(yearAndMonth: Array<number>, months: number): Array<number> {
    let newYearAndMonth: Array<number> = [...yearAndMonth];

    const yearsSkip = (Math.abs(months) / 12) | 0;
    const monthsToSkip = (Math.abs(months) % 12) | 0;

    newYearAndMonth[0] = newYearAndMonth[0] + ((months > 0) ? yearsSkip : -yearsSkip);

    const resultingMonth = newYearAndMonth[1] + ((months > 0) ? monthsToSkip : -monthsToSkip)

    if (resultingMonth < 1) {
      newYearAndMonth[0] = newYearAndMonth[0] - 1;
      newYearAndMonth[1] = 12 - (Math.abs(resultingMonth) | 0);
    } else if (resultingMonth > 12) {
      newYearAndMonth[0] = newYearAndMonth[0] + 1;
      newYearAndMonth[1] = resultingMonth - 12;
    } else {
      newYearAndMonth[1] = resultingMonth;
    }

    return newYearAndMonth;
  }

  static getPreviousMonth(yearAndMonth: Array<number>): Array<number> {
    return this.shiftXMonthsFrom(yearAndMonth, -1);
  }

  static getNextMonth(yearAndMonth: Array<number>): Array<number> {
    return this.shiftXMonthsFrom(yearAndMonth, 1);
  }

  static formatYearAndMonthToValue(yearAndMonth: Array<number>): string {
    return yearAndMonth[0] + '-' + yearAndMonth[1];
  }

  static getPrecedingYearAndMonth(yearAndMonth: Array<number>) {
    let prevMonth = yearAndMonth[1] - 1;
    if (prevMonth > 0) {
      return [yearAndMonth[0], prevMonth];
    } else {
      prevMonth = 12;
      const prevYear = yearAndMonth[0] - 1;
      return [prevYear, prevMonth];
    }
  }

  static getFollowingYearAndMonth(yearAndMonth: Array<number>) {
    let nextMonth = yearAndMonth[1] + 1;
    if (nextMonth <= 12) {
      return [yearAndMonth[0], nextMonth];
    } else {
      nextMonth = 1;
      const nextYear = yearAndMonth[0] + 1;
      return [nextYear, nextMonth];
    }
  }

  static getYearMonthsBetween(startYearAndMonth: string, endYearAndMonth: string): Array<string> {
    const last = Utils.getYearAndMonthFromString(endYearAndMonth);

    let curr = Utils.getYearAndMonthFromString(startYearAndMonth);

    let toRet = new Array<string>();
    toRet.push(Utils.getYearAndMonthPrefix(curr));

    while(curr[0] != last[0] || curr[1] != last[1]) {
      const next = Utils.getFollowingYearAndMonth(curr);
      toRet.push(Utils.getYearAndMonthPrefix(next));
      curr = next;
    }

    return toRet;
  }
}
