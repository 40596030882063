/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { Client, generateClient, GraphQLResult } from "aws-amplify/api";
import { Observable } from "rxjs";

export type __SubscriptionContainer = {
  onCreateCategory: OnCreateCategorySubscription;
  onUpdateCategory: OnUpdateCategorySubscription;
  onDeleteCategory: OnDeleteCategorySubscription;
  onCreateAccount: OnCreateAccountSubscription;
  onUpdateAccount: OnUpdateAccountSubscription;
  onDeleteAccount: OnDeleteAccountSubscription;
  onCreateAccountFile: OnCreateAccountFileSubscription;
  onUpdateAccountFile: OnUpdateAccountFileSubscription;
  onDeleteAccountFile: OnDeleteAccountFileSubscription;
  onCreateTransaction: OnCreateTransactionSubscription;
  onUpdateTransaction: OnUpdateTransactionSubscription;
  onDeleteTransaction: OnDeleteTransactionSubscription;
  onCreateTransactionFile: OnCreateTransactionFileSubscription;
  onUpdateTransactionFile: OnUpdateTransactionFileSubscription;
  onDeleteTransactionFile: OnDeleteTransactionFileSubscription;
  onCreateTag: OnCreateTagSubscription;
  onUpdateTag: OnUpdateTagSubscription;
  onDeleteTag: OnDeleteTagSubscription;
  onCreateMonthlyBudgetEntry: OnCreateMonthlyBudgetEntrySubscription;
  onUpdateMonthlyBudgetEntry: OnUpdateMonthlyBudgetEntrySubscription;
  onDeleteMonthlyBudgetEntry: OnDeleteMonthlyBudgetEntrySubscription;
};

export type CreateCategoryInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  _version?: number | null;
};

export type ModelCategoryConditionInput = {
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  sortingPosition?: ModelIntInput | null;
  hidden?: ModelBooleanInput | null;
  and?: Array<ModelCategoryConditionInput | null> | null;
  or?: Array<ModelCategoryConditionInput | null> | null;
  not?: ModelCategoryConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Category = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCategoryInput = {
  owner?: string | null;
  username: string;
  id: string;
  name?: string | null;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  _version?: number | null;
};

export type DeleteCategoryInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateAccountInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  _version?: number | null;
};

export type ModelAccountConditionInput = {
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  budgeted?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  hidden?: ModelBooleanInput | null;
  and?: Array<ModelAccountConditionInput | null> | null;
  or?: Array<ModelAccountConditionInput | null> | null;
  not?: ModelAccountConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Account = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: ModelAccountFileConnection | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelAccountFileConnection = {
  __typename: "ModelAccountFileConnection";
  items: Array<AccountFile | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type AccountFile = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: Account;
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAccountInput = {
  owner?: string | null;
  username: string;
  id: string;
  name?: string | null;
  budgeted?: boolean | null;
  description?: string | null;
  hidden?: boolean | null;
  _version?: number | null;
};

export type DeleteAccountInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateAccountFileInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  accountId: string;
  name: string;
  description?: string | null;
  filePath: string;
  _version?: number | null;
};

export type ModelAccountFileConditionInput = {
  owner?: ModelStringInput | null;
  accountId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  filePath?: ModelStringInput | null;
  and?: Array<ModelAccountFileConditionInput | null> | null;
  or?: Array<ModelAccountFileConditionInput | null> | null;
  not?: ModelAccountFileConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateAccountFileInput = {
  owner?: string | null;
  username: string;
  id: string;
  accountId?: string | null;
  name?: string | null;
  description?: string | null;
  filePath?: string | null;
  _version?: number | null;
};

export type DeleteAccountFileInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateTransactionInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  accountId: string;
  categoryId: string;
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  _version?: number | null;
};

export enum Direction {
  INCOME = "INCOME",
  EXPENSE = "EXPENSE"
}

export type ModelTransactionConditionInput = {
  owner?: ModelStringInput | null;
  accountId?: ModelIDInput | null;
  categoryId?: ModelIDInput | null;
  payee?: ModelStringInput | null;
  amountInCents?: ModelIntInput | null;
  direction?: ModelDirectionInput | null;
  transactionDate?: ModelStringInput | null;
  note?: ModelStringInput | null;
  needsCheck?: ModelBooleanInput | null;
  and?: Array<ModelTransactionConditionInput | null> | null;
  or?: Array<ModelTransactionConditionInput | null> | null;
  not?: ModelTransactionConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelDirectionInput = {
  eq?: Direction | null;
  ne?: Direction | null;
};

export type Transaction = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: Account;
  categoryId: string;
  category: Category;
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: ModelTransactionFileConnection | null;
  tags?: ModelTagConnection | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelTransactionFileConnection = {
  __typename: "ModelTransactionFileConnection";
  items: Array<TransactionFile | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TransactionFile = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: Transaction;
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection";
  items: Array<Tag | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Tag = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateTransactionInput = {
  owner?: string | null;
  username: string;
  id: string;
  accountId?: string | null;
  categoryId?: string | null;
  payee?: string | null;
  amountInCents?: number | null;
  direction?: Direction | null;
  transactionDate?: string | null;
  note?: string | null;
  needsCheck?: boolean | null;
  _version?: number | null;
};

export type DeleteTransactionInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateTransactionFileInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  transactionId: string;
  name: string;
  description?: string | null;
  filePath: string;
  _version?: number | null;
};

export type ModelTransactionFileConditionInput = {
  owner?: ModelStringInput | null;
  transactionId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  filePath?: ModelStringInput | null;
  and?: Array<ModelTransactionFileConditionInput | null> | null;
  or?: Array<ModelTransactionFileConditionInput | null> | null;
  not?: ModelTransactionFileConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateTransactionFileInput = {
  owner?: string | null;
  username: string;
  id: string;
  transactionId?: string | null;
  name?: string | null;
  description?: string | null;
  filePath?: string | null;
  _version?: number | null;
};

export type DeleteTransactionFileInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateTagInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  tagId: string;
  name: string;
  description?: string | null;
  _version?: number | null;
};

export type ModelTagConditionInput = {
  owner?: ModelStringInput | null;
  tagId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelTagConditionInput | null> | null;
  or?: Array<ModelTagConditionInput | null> | null;
  not?: ModelTagConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateTagInput = {
  owner?: string | null;
  username: string;
  id: string;
  tagId?: string | null;
  name?: string | null;
  description?: string | null;
  _version?: number | null;
};

export type DeleteTagInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type CreateMonthlyBudgetEntryInput = {
  owner?: string | null;
  username: string;
  id?: string | null;
  budgetedAmountInCents: number;
  categoryId: string;
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  _version?: number | null;
};

export type ModelMonthlyBudgetEntryConditionInput = {
  owner?: ModelStringInput | null;
  budgetedAmountInCents?: ModelIntInput | null;
  categoryId?: ModelIDInput | null;
  monthlyBudgetYearAndMonth?: ModelStringInput | null;
  categoryYearAndMonth?: ModelStringInput | null;
  and?: Array<ModelMonthlyBudgetEntryConditionInput | null> | null;
  or?: Array<ModelMonthlyBudgetEntryConditionInput | null> | null;
  not?: ModelMonthlyBudgetEntryConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type MonthlyBudgetEntry = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: Category;
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMonthlyBudgetEntryInput = {
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents?: number | null;
  categoryId?: string | null;
  monthlyBudgetYearAndMonth?: string | null;
  categoryYearAndMonth?: string | null;
  _version?: number | null;
};

export type DeleteMonthlyBudgetEntryInput = {
  username: string;
  id: string;
  _version?: number | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelCategoryFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  sortingPosition?: ModelIntInput | null;
  hidden?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCategoryFilterInput | null> | null;
  or?: Array<ModelCategoryFilterInput | null> | null;
  not?: ModelCategoryFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection";
  items: Array<Category | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelAccountFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  budgeted?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  hidden?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAccountFilterInput | null> | null;
  or?: Array<ModelAccountFilterInput | null> | null;
  not?: ModelAccountFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection";
  items: Array<Account | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelAccountFileFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  filePath?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAccountFileFilterInput | null> | null;
  or?: Array<ModelAccountFileFilterInput | null> | null;
  not?: ModelAccountFileFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelTransactionFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  categoryId?: ModelIDInput | null;
  payee?: ModelStringInput | null;
  amountInCents?: ModelIntInput | null;
  direction?: ModelDirectionInput | null;
  transactionDate?: ModelStringInput | null;
  note?: ModelStringInput | null;
  needsCheck?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTransactionFilterInput | null> | null;
  or?: Array<ModelTransactionFilterInput | null> | null;
  not?: ModelTransactionFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection";
  items: Array<Transaction | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelTransactionFileFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  transactionId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  filePath?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTransactionFileFilterInput | null> | null;
  or?: Array<ModelTransactionFileFilterInput | null> | null;
  not?: ModelTransactionFileFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelTagFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  tagId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTagFilterInput | null> | null;
  or?: Array<ModelTagFilterInput | null> | null;
  not?: ModelTagFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelMonthlyBudgetEntryFilterInput = {
  owner?: ModelStringInput | null;
  username?: ModelStringInput | null;
  id?: ModelIDInput | null;
  budgetedAmountInCents?: ModelIntInput | null;
  categoryId?: ModelIDInput | null;
  monthlyBudgetYearAndMonth?: ModelStringInput | null;
  categoryYearAndMonth?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMonthlyBudgetEntryFilterInput | null> | null;
  or?: Array<ModelMonthlyBudgetEntryFilterInput | null> | null;
  not?: ModelMonthlyBudgetEntryFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelMonthlyBudgetEntryConnection = {
  __typename: "ModelMonthlyBudgetEntryConnection";
  items: Array<MonthlyBudgetEntry | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelSubscriptionCategoryFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  sortingPosition?: ModelSubscriptionIntInput | null;
  hidden?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCategoryFilterInput | null> | null;
  or?: Array<ModelSubscriptionCategoryFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionAccountFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  budgeted?: ModelSubscriptionBooleanInput | null;
  description?: ModelSubscriptionStringInput | null;
  hidden?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAccountFilterInput | null> | null;
  or?: Array<ModelSubscriptionAccountFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionAccountFileFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  accountId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  filePath?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAccountFileFilterInput | null> | null;
  or?: Array<ModelSubscriptionAccountFileFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionTransactionFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  accountId?: ModelSubscriptionIDInput | null;
  categoryId?: ModelSubscriptionIDInput | null;
  payee?: ModelSubscriptionStringInput | null;
  amountInCents?: ModelSubscriptionIntInput | null;
  direction?: ModelSubscriptionStringInput | null;
  transactionDate?: ModelSubscriptionStringInput | null;
  note?: ModelSubscriptionStringInput | null;
  needsCheck?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTransactionFilterInput | null> | null;
  or?: Array<ModelSubscriptionTransactionFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionTransactionFileFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  transactionId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  filePath?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTransactionFileFilterInput | null> | null;
  or?: Array<ModelSubscriptionTransactionFileFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionTagFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  tagId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTagFilterInput | null> | null;
  or?: Array<ModelSubscriptionTagFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type ModelSubscriptionMonthlyBudgetEntryFilterInput = {
  username?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  budgetedAmountInCents?: ModelSubscriptionIntInput | null;
  categoryId?: ModelSubscriptionIDInput | null;
  monthlyBudgetYearAndMonth?: ModelSubscriptionStringInput | null;
  categoryYearAndMonth?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMonthlyBudgetEntryFilterInput | null> | null;
  or?: Array<ModelSubscriptionMonthlyBudgetEntryFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
};

export type CreateCategoryMutation = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCategoryMutation = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCategoryMutation = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateAccountMutation = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAccountMutation = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteAccountMutation = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateAccountFileMutation = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateAccountFileMutation = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteAccountFileMutation = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateTransactionMutation = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateTransactionMutation = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteTransactionMutation = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateTransactionFileMutation = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateTransactionFileMutation = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteTransactionFileMutation = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateTagMutation = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateTagMutation = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteTagMutation = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateMonthlyBudgetEntryMutation = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateMonthlyBudgetEntryMutation = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteMonthlyBudgetEntryMutation = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type GetCategoryQuery = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListCategoriesQuery = {
  __typename: "ModelCategoryConnection";
  items: Array<{
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCategoriesQuery = {
  __typename: "ModelCategoryConnection";
  items: Array<{
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAccountQuery = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListAccountsQuery = {
  __typename: "ModelAccountConnection";
  items: Array<{
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAccountsQuery = {
  __typename: "ModelAccountConnection";
  items: Array<{
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAccountFileQuery = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListAccountFilesQuery = {
  __typename: "ModelAccountFileConnection";
  items: Array<{
    __typename: "AccountFile";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    name: string;
    description?: string | null;
    filePath: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAccountFilesQuery = {
  __typename: "ModelAccountFileConnection";
  items: Array<{
    __typename: "AccountFile";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    name: string;
    description?: string | null;
    filePath: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetTransactionQuery = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListTransactionsQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTransactionsQuery = {
  __typename: "ModelTransactionConnection";
  items: Array<{
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetTransactionFileQuery = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListTransactionFilesQuery = {
  __typename: "ModelTransactionFileConnection";
  items: Array<{
    __typename: "TransactionFile";
    owner?: string | null;
    username: string;
    id: string;
    transactionId: string;
    transaction: {
      __typename: "Transaction";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      categoryId: string;
      payee: string;
      amountInCents: number;
      direction: Direction;
      transactionDate: string;
      note?: string | null;
      needsCheck?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    name: string;
    description?: string | null;
    filePath: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTransactionFilesQuery = {
  __typename: "ModelTransactionFileConnection";
  items: Array<{
    __typename: "TransactionFile";
    owner?: string | null;
    username: string;
    id: string;
    transactionId: string;
    transaction: {
      __typename: "Transaction";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      categoryId: string;
      payee: string;
      amountInCents: number;
      direction: Direction;
      transactionDate: string;
      note?: string | null;
      needsCheck?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    name: string;
    description?: string | null;
    filePath: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetTagQuery = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListTagsQuery = {
  __typename: "ModelTagConnection";
  items: Array<{
    __typename: "Tag";
    owner?: string | null;
    username: string;
    id: string;
    tagId: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTagsQuery = {
  __typename: "ModelTagConnection";
  items: Array<{
    __typename: "Tag";
    owner?: string | null;
    username: string;
    id: string;
    tagId: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetMonthlyBudgetEntryQuery = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListMonthlyBudgetEntriesQuery = {
  __typename: "ModelMonthlyBudgetEntryConnection";
  items: Array<{
    __typename: "MonthlyBudgetEntry";
    owner?: string | null;
    username: string;
    id: string;
    budgetedAmountInCents: number;
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    monthlyBudgetYearAndMonth: string;
    categoryYearAndMonth: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncMonthlyBudgetEntriesQuery = {
  __typename: "ModelMonthlyBudgetEntryConnection";
  items: Array<{
    __typename: "MonthlyBudgetEntry";
    owner?: string | null;
    username: string;
    id: string;
    budgetedAmountInCents: number;
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    monthlyBudgetYearAndMonth: string;
    categoryYearAndMonth: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OnCreateCategorySubscription = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCategorySubscription = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCategorySubscription = {
  __typename: "Category";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  description?: string | null;
  sortingPosition?: number | null;
  hidden?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateAccountSubscription = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateAccountSubscription = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteAccountSubscription = {
  __typename: "Account";
  owner?: string | null;
  username: string;
  id: string;
  name: string;
  budgeted: boolean;
  description?: string | null;
  hidden?: boolean | null;
  files?: {
    __typename: "ModelAccountFileConnection";
    items: Array<{
      __typename: "AccountFile";
      owner?: string | null;
      username: string;
      id: string;
      accountId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateAccountFileSubscription = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateAccountFileSubscription = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteAccountFileSubscription = {
  __typename: "AccountFile";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateTransactionSubscription = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateTransactionSubscription = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteTransactionSubscription = {
  __typename: "Transaction";
  owner?: string | null;
  username: string;
  id: string;
  accountId: string;
  account: {
    __typename: "Account";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    budgeted: boolean;
    description?: string | null;
    hidden?: boolean | null;
    files?: {
      __typename: "ModelAccountFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  payee: string;
  amountInCents: number;
  direction: Direction;
  transactionDate: string;
  note?: string | null;
  needsCheck?: boolean | null;
  files?: {
    __typename: "ModelTransactionFileConnection";
    items: Array<{
      __typename: "TransactionFile";
      owner?: string | null;
      username: string;
      id: string;
      transactionId: string;
      name: string;
      description?: string | null;
      filePath: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  tags?: {
    __typename: "ModelTagConnection";
    items: Array<{
      __typename: "Tag";
      owner?: string | null;
      username: string;
      id: string;
      tagId: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateTransactionFileSubscription = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateTransactionFileSubscription = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteTransactionFileSubscription = {
  __typename: "TransactionFile";
  owner?: string | null;
  username: string;
  id: string;
  transactionId: string;
  transaction: {
    __typename: "Transaction";
    owner?: string | null;
    username: string;
    id: string;
    accountId: string;
    account: {
      __typename: "Account";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      budgeted: boolean;
      description?: string | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    categoryId: string;
    category: {
      __typename: "Category";
      owner?: string | null;
      username: string;
      id: string;
      name: string;
      description?: string | null;
      sortingPosition?: number | null;
      hidden?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    };
    payee: string;
    amountInCents: number;
    direction: Direction;
    transactionDate: string;
    note?: string | null;
    needsCheck?: boolean | null;
    files?: {
      __typename: "ModelTransactionFileConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    tags?: {
      __typename: "ModelTagConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  name: string;
  description?: string | null;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateTagSubscription = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateTagSubscription = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteTagSubscription = {
  __typename: "Tag";
  owner?: string | null;
  username: string;
  id: string;
  tagId: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateMonthlyBudgetEntrySubscription = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateMonthlyBudgetEntrySubscription = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteMonthlyBudgetEntrySubscription = {
  __typename: "MonthlyBudgetEntry";
  owner?: string | null;
  username: string;
  id: string;
  budgetedAmountInCents: number;
  categoryId: string;
  category: {
    __typename: "Category";
    owner?: string | null;
    username: string;
    id: string;
    name: string;
    description?: string | null;
    sortingPosition?: number | null;
    hidden?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  };
  monthlyBudgetYearAndMonth: string;
  categoryYearAndMonth: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  public client: Client;
  constructor() {
    this.client = generateClient();
  }
  async CreateCategory(
    input: CreateCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<CreateCategoryMutation> {
    const statement = `mutation CreateCategory($input: CreateCategoryInput!, $condition: ModelCategoryConditionInput) {
        createCategory(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateCategoryMutation>response.data.createCategory;
  }
  async UpdateCategory(
    input: UpdateCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<UpdateCategoryMutation> {
    const statement = `mutation UpdateCategory($input: UpdateCategoryInput!, $condition: ModelCategoryConditionInput) {
        updateCategory(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateCategoryMutation>response.data.updateCategory;
  }
  async DeleteCategory(
    input: DeleteCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<DeleteCategoryMutation> {
    const statement = `mutation DeleteCategory($input: DeleteCategoryInput!, $condition: ModelCategoryConditionInput) {
        deleteCategory(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteCategoryMutation>response.data.deleteCategory;
  }
  async CreateAccount(
    input: CreateAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<CreateAccountMutation> {
    const statement = `mutation CreateAccount($input: CreateAccountInput!, $condition: ModelAccountConditionInput) {
        createAccount(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateAccountMutation>response.data.createAccount;
  }
  async UpdateAccount(
    input: UpdateAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<UpdateAccountMutation> {
    const statement = `mutation UpdateAccount($input: UpdateAccountInput!, $condition: ModelAccountConditionInput) {
        updateAccount(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateAccountMutation>response.data.updateAccount;
  }
  async DeleteAccount(
    input: DeleteAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<DeleteAccountMutation> {
    const statement = `mutation DeleteAccount($input: DeleteAccountInput!, $condition: ModelAccountConditionInput) {
        deleteAccount(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteAccountMutation>response.data.deleteAccount;
  }
  async CreateAccountFile(
    input: CreateAccountFileInput,
    condition?: ModelAccountFileConditionInput
  ): Promise<CreateAccountFileMutation> {
    const statement = `mutation CreateAccountFile($input: CreateAccountFileInput!, $condition: ModelAccountFileConditionInput) {
        createAccountFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateAccountFileMutation>response.data.createAccountFile;
  }
  async UpdateAccountFile(
    input: UpdateAccountFileInput,
    condition?: ModelAccountFileConditionInput
  ): Promise<UpdateAccountFileMutation> {
    const statement = `mutation UpdateAccountFile($input: UpdateAccountFileInput!, $condition: ModelAccountFileConditionInput) {
        updateAccountFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateAccountFileMutation>response.data.updateAccountFile;
  }
  async DeleteAccountFile(
    input: DeleteAccountFileInput,
    condition?: ModelAccountFileConditionInput
  ): Promise<DeleteAccountFileMutation> {
    const statement = `mutation DeleteAccountFile($input: DeleteAccountFileInput!, $condition: ModelAccountFileConditionInput) {
        deleteAccountFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteAccountFileMutation>response.data.deleteAccountFile;
  }
  async CreateTransaction(
    input: CreateTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<CreateTransactionMutation> {
    const statement = `mutation CreateTransaction($input: CreateTransactionInput!, $condition: ModelTransactionConditionInput) {
        createTransaction(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateTransactionMutation>response.data.createTransaction;
  }
  async UpdateTransaction(
    input: UpdateTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<UpdateTransactionMutation> {
    const statement = `mutation UpdateTransaction($input: UpdateTransactionInput!, $condition: ModelTransactionConditionInput) {
        updateTransaction(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateTransactionMutation>response.data.updateTransaction;
  }
  async DeleteTransaction(
    input: DeleteTransactionInput,
    condition?: ModelTransactionConditionInput
  ): Promise<DeleteTransactionMutation> {
    const statement = `mutation DeleteTransaction($input: DeleteTransactionInput!, $condition: ModelTransactionConditionInput) {
        deleteTransaction(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteTransactionMutation>response.data.deleteTransaction;
  }
  async CreateTransactionFile(
    input: CreateTransactionFileInput,
    condition?: ModelTransactionFileConditionInput
  ): Promise<CreateTransactionFileMutation> {
    const statement = `mutation CreateTransactionFile($input: CreateTransactionFileInput!, $condition: ModelTransactionFileConditionInput) {
        createTransactionFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateTransactionFileMutation>response.data.createTransactionFile;
  }
  async UpdateTransactionFile(
    input: UpdateTransactionFileInput,
    condition?: ModelTransactionFileConditionInput
  ): Promise<UpdateTransactionFileMutation> {
    const statement = `mutation UpdateTransactionFile($input: UpdateTransactionFileInput!, $condition: ModelTransactionFileConditionInput) {
        updateTransactionFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateTransactionFileMutation>response.data.updateTransactionFile;
  }
  async DeleteTransactionFile(
    input: DeleteTransactionFileInput,
    condition?: ModelTransactionFileConditionInput
  ): Promise<DeleteTransactionFileMutation> {
    const statement = `mutation DeleteTransactionFile($input: DeleteTransactionFileInput!, $condition: ModelTransactionFileConditionInput) {
        deleteTransactionFile(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteTransactionFileMutation>response.data.deleteTransactionFile;
  }
  async CreateTag(
    input: CreateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<CreateTagMutation> {
    const statement = `mutation CreateTag($input: CreateTagInput!, $condition: ModelTagConditionInput) {
        createTag(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateTagMutation>response.data.createTag;
  }
  async UpdateTag(
    input: UpdateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<UpdateTagMutation> {
    const statement = `mutation UpdateTag($input: UpdateTagInput!, $condition: ModelTagConditionInput) {
        updateTag(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateTagMutation>response.data.updateTag;
  }
  async DeleteTag(
    input: DeleteTagInput,
    condition?: ModelTagConditionInput
  ): Promise<DeleteTagMutation> {
    const statement = `mutation DeleteTag($input: DeleteTagInput!, $condition: ModelTagConditionInput) {
        deleteTag(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteTagMutation>response.data.deleteTag;
  }
  async CreateMonthlyBudgetEntry(
    input: CreateMonthlyBudgetEntryInput,
    condition?: ModelMonthlyBudgetEntryConditionInput
  ): Promise<CreateMonthlyBudgetEntryMutation> {
    const statement = `mutation CreateMonthlyBudgetEntry($input: CreateMonthlyBudgetEntryInput!, $condition: ModelMonthlyBudgetEntryConditionInput) {
        createMonthlyBudgetEntry(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateMonthlyBudgetEntryMutation>(
      response.data.createMonthlyBudgetEntry
    );
  }
  async UpdateMonthlyBudgetEntry(
    input: UpdateMonthlyBudgetEntryInput,
    condition?: ModelMonthlyBudgetEntryConditionInput
  ): Promise<UpdateMonthlyBudgetEntryMutation> {
    const statement = `mutation UpdateMonthlyBudgetEntry($input: UpdateMonthlyBudgetEntryInput!, $condition: ModelMonthlyBudgetEntryConditionInput) {
        updateMonthlyBudgetEntry(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateMonthlyBudgetEntryMutation>(
      response.data.updateMonthlyBudgetEntry
    );
  }
  async DeleteMonthlyBudgetEntry(
    input: DeleteMonthlyBudgetEntryInput,
    condition?: ModelMonthlyBudgetEntryConditionInput
  ): Promise<DeleteMonthlyBudgetEntryMutation> {
    const statement = `mutation DeleteMonthlyBudgetEntry($input: DeleteMonthlyBudgetEntryInput!, $condition: ModelMonthlyBudgetEntryConditionInput) {
        deleteMonthlyBudgetEntry(input: $input, condition: $condition) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteMonthlyBudgetEntryMutation>(
      response.data.deleteMonthlyBudgetEntry
    );
  }
  async GetCategory(username: string, id: string): Promise<GetCategoryQuery> {
    const statement = `query GetCategory($username: String!, $id: ID!) {
        getCategory(username: $username, id: $id) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetCategoryQuery>response.data.getCategory;
  }
  async ListCategories(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCategoriesQuery> {
    const statement = `query ListCategories($username: String, $id: ModelIDKeyConditionInput, $filter: ModelCategoryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCategories(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListCategoriesQuery>response.data.listCategories;
  }
  async SyncCategories(
    filter?: ModelCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCategoriesQuery> {
    const statement = `query SyncCategories($filter: ModelCategoryFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCategories(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncCategoriesQuery>response.data.syncCategories;
  }
  async GetAccount(username: string, id: string): Promise<GetAccountQuery> {
    const statement = `query GetAccount($username: String!, $id: ID!) {
        getAccount(username: $username, id: $id) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetAccountQuery>response.data.getAccount;
  }
  async ListAccounts(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelAccountFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAccountsQuery> {
    const statement = `query ListAccounts($username: String, $id: ModelIDKeyConditionInput, $filter: ModelAccountFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAccounts(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListAccountsQuery>response.data.listAccounts;
  }
  async SyncAccounts(
    filter?: ModelAccountFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAccountsQuery> {
    const statement = `query SyncAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAccounts(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncAccountsQuery>response.data.syncAccounts;
  }
  async GetAccountFile(
    username: string,
    id: string
  ): Promise<GetAccountFileQuery> {
    const statement = `query GetAccountFile($username: String!, $id: ID!) {
        getAccountFile(username: $username, id: $id) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetAccountFileQuery>response.data.getAccountFile;
  }
  async ListAccountFiles(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelAccountFileFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAccountFilesQuery> {
    const statement = `query ListAccountFiles($username: String, $id: ModelIDKeyConditionInput, $filter: ModelAccountFileFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAccountFiles(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            name
            description
            filePath
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListAccountFilesQuery>response.data.listAccountFiles;
  }
  async SyncAccountFiles(
    filter?: ModelAccountFileFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAccountFilesQuery> {
    const statement = `query SyncAccountFiles($filter: ModelAccountFileFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAccountFiles(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            name
            description
            filePath
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncAccountFilesQuery>response.data.syncAccountFiles;
  }
  async GetTransaction(
    username: string,
    id: string
  ): Promise<GetTransactionQuery> {
    const statement = `query GetTransaction($username: String!, $id: ID!) {
        getTransaction(username: $username, id: $id) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetTransactionQuery>response.data.getTransaction;
  }
  async ListTransactions(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelTransactionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTransactionsQuery> {
    const statement = `query ListTransactions($username: String, $id: ModelIDKeyConditionInput, $filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTransactions(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListTransactionsQuery>response.data.listTransactions;
  }
  async SyncTransactions(
    filter?: ModelTransactionFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTransactionsQuery> {
    const statement = `query SyncTransactions($filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTransactions(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncTransactionsQuery>response.data.syncTransactions;
  }
  async GetTransactionFile(
    username: string,
    id: string
  ): Promise<GetTransactionFileQuery> {
    const statement = `query GetTransactionFile($username: String!, $id: ID!) {
        getTransactionFile(username: $username, id: $id) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetTransactionFileQuery>response.data.getTransactionFile;
  }
  async ListTransactionFiles(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelTransactionFileFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTransactionFilesQuery> {
    const statement = `query ListTransactionFiles($username: String, $id: ModelIDKeyConditionInput, $filter: ModelTransactionFileFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTransactionFiles(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            transactionId
            transaction {
              __typename
              owner
              username
              id
              accountId
              categoryId
              payee
              amountInCents
              direction
              transactionDate
              note
              needsCheck
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            name
            description
            filePath
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListTransactionFilesQuery>response.data.listTransactionFiles;
  }
  async SyncTransactionFiles(
    filter?: ModelTransactionFileFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTransactionFilesQuery> {
    const statement = `query SyncTransactionFiles($filter: ModelTransactionFileFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTransactionFiles(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            transactionId
            transaction {
              __typename
              owner
              username
              id
              accountId
              categoryId
              payee
              amountInCents
              direction
              transactionDate
              note
              needsCheck
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            name
            description
            filePath
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncTransactionFilesQuery>response.data.syncTransactionFiles;
  }
  async GetTag(username: string, id: string): Promise<GetTagQuery> {
    const statement = `query GetTag($username: String!, $id: ID!) {
        getTag(username: $username, id: $id) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetTagQuery>response.data.getTag;
  }
  async ListTags(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelTagFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTagsQuery> {
    const statement = `query ListTags($username: String, $id: ModelIDKeyConditionInput, $filter: ModelTagFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTags(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            tagId
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListTagsQuery>response.data.listTags;
  }
  async SyncTags(
    filter?: ModelTagFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTagsQuery> {
    const statement = `query SyncTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTags(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            tagId
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncTagsQuery>response.data.syncTags;
  }
  async GetMonthlyBudgetEntry(
    username: string,
    id: string
  ): Promise<GetMonthlyBudgetEntryQuery> {
    const statement = `query GetMonthlyBudgetEntry($username: String!, $id: ID!) {
        getMonthlyBudgetEntry(username: $username, id: $id) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      id
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetMonthlyBudgetEntryQuery>response.data.getMonthlyBudgetEntry;
  }
  async ListMonthlyBudgetEntries(
    username?: string,
    id?: ModelIDKeyConditionInput,
    filter?: ModelMonthlyBudgetEntryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMonthlyBudgetEntriesQuery> {
    const statement = `query ListMonthlyBudgetEntries($username: String, $id: ModelIDKeyConditionInput, $filter: ModelMonthlyBudgetEntryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMonthlyBudgetEntries(
          username: $username
          id: $id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            budgetedAmountInCents
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            monthlyBudgetYearAndMonth
            categoryYearAndMonth
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListMonthlyBudgetEntriesQuery>(
      response.data.listMonthlyBudgetEntries
    );
  }
  async SyncMonthlyBudgetEntries(
    filter?: ModelMonthlyBudgetEntryFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncMonthlyBudgetEntriesQuery> {
    const statement = `query SyncMonthlyBudgetEntries($filter: ModelMonthlyBudgetEntryFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncMonthlyBudgetEntries(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            owner
            username
            id
            budgetedAmountInCents
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            monthlyBudgetYearAndMonth
            categoryYearAndMonth
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <SyncMonthlyBudgetEntriesQuery>(
      response.data.syncMonthlyBudgetEntries
    );
  }
  OnCreateCategoryListener(
    filter?: ModelSubscriptionCategoryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateCategory">>
  > {
    const statement = `subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput, $owner: String) {
        onCreateCategory(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateCategoryListener(
    filter?: ModelSubscriptionCategoryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateCategory">>
  > {
    const statement = `subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput, $owner: String) {
        onUpdateCategory(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteCategoryListener(
    filter?: ModelSubscriptionCategoryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteCategory">>
  > {
    const statement = `subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput, $owner: String) {
        onDeleteCategory(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          description
          sortingPosition
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateAccountListener(
    filter?: ModelSubscriptionAccountFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateAccount">>
  > {
    const statement = `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput, $owner: String) {
        onCreateAccount(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateAccountListener(
    filter?: ModelSubscriptionAccountFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateAccount">>
  > {
    const statement = `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput, $owner: String) {
        onUpdateAccount(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteAccountListener(
    filter?: ModelSubscriptionAccountFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteAccount">>
  > {
    const statement = `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput, $owner: String) {
        onDeleteAccount(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          name
          budgeted
          description
          hidden
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              accountId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateAccountFileListener(
    filter?: ModelSubscriptionAccountFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateAccountFile">>
  > {
    const statement = `subscription OnCreateAccountFile($filter: ModelSubscriptionAccountFileFilterInput, $owner: String) {
        onCreateAccountFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateAccountFileListener(
    filter?: ModelSubscriptionAccountFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateAccountFile">>
  > {
    const statement = `subscription OnUpdateAccountFile($filter: ModelSubscriptionAccountFileFilterInput, $owner: String) {
        onUpdateAccountFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteAccountFileListener(
    filter?: ModelSubscriptionAccountFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteAccountFile">>
  > {
    const statement = `subscription OnDeleteAccountFile($filter: ModelSubscriptionAccountFileFilterInput, $owner: String) {
        onDeleteAccountFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateTransaction">>
  > {
    const statement = `subscription OnCreateTransaction($filter: ModelSubscriptionTransactionFilterInput, $owner: String) {
        onCreateTransaction(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateTransaction">>
  > {
    const statement = `subscription OnUpdateTransaction($filter: ModelSubscriptionTransactionFilterInput, $owner: String) {
        onUpdateTransaction(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteTransactionListener(
    filter?: ModelSubscriptionTransactionFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteTransaction">>
  > {
    const statement = `subscription OnDeleteTransaction($filter: ModelSubscriptionTransactionFilterInput, $owner: String) {
        onDeleteTransaction(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          accountId
          account {
            __typename
            owner
            username
            id
            name
            budgeted
            description
            hidden
            files {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          payee
          amountInCents
          direction
          transactionDate
          note
          needsCheck
          files {
            __typename
            items {
              __typename
              owner
              username
              id
              transactionId
              name
              description
              filePath
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          tags {
            __typename
            items {
              __typename
              owner
              username
              id
              tagId
              name
              description
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateTransactionFileListener(
    filter?: ModelSubscriptionTransactionFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateTransactionFile">>
  > {
    const statement = `subscription OnCreateTransactionFile($filter: ModelSubscriptionTransactionFileFilterInput, $owner: String) {
        onCreateTransactionFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateTransactionFileListener(
    filter?: ModelSubscriptionTransactionFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateTransactionFile">>
  > {
    const statement = `subscription OnUpdateTransactionFile($filter: ModelSubscriptionTransactionFileFilterInput, $owner: String) {
        onUpdateTransactionFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteTransactionFileListener(
    filter?: ModelSubscriptionTransactionFileFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteTransactionFile">>
  > {
    const statement = `subscription OnDeleteTransactionFile($filter: ModelSubscriptionTransactionFileFilterInput, $owner: String) {
        onDeleteTransactionFile(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          transactionId
          transaction {
            __typename
            owner
            username
            id
            accountId
            account {
              __typename
              owner
              username
              id
              name
              budgeted
              description
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            categoryId
            category {
              __typename
              owner
              username
              id
              name
              description
              sortingPosition
              hidden
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            payee
            amountInCents
            direction
            transactionDate
            note
            needsCheck
            files {
              __typename
              nextToken
              startedAt
            }
            tags {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          name
          description
          filePath
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateTagListener(
    filter?: ModelSubscriptionTagFilterInput,
    owner?: string
  ): Observable<GraphQLResult<Pick<__SubscriptionContainer, "onCreateTag">>> {
    const statement = `subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput, $owner: String) {
        onCreateTag(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateTagListener(
    filter?: ModelSubscriptionTagFilterInput,
    owner?: string
  ): Observable<GraphQLResult<Pick<__SubscriptionContainer, "onUpdateTag">>> {
    const statement = `subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput, $owner: String) {
        onUpdateTag(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteTagListener(
    filter?: ModelSubscriptionTagFilterInput,
    owner?: string
  ): Observable<GraphQLResult<Pick<__SubscriptionContainer, "onDeleteTag">>> {
    const statement = `subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput, $owner: String) {
        onDeleteTag(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          tagId
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateMonthlyBudgetEntryListener(
    filter?: ModelSubscriptionMonthlyBudgetEntryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateMonthlyBudgetEntry">>
  > {
    const statement = `subscription OnCreateMonthlyBudgetEntry($filter: ModelSubscriptionMonthlyBudgetEntryFilterInput, $owner: String) {
        onCreateMonthlyBudgetEntry(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateMonthlyBudgetEntryListener(
    filter?: ModelSubscriptionMonthlyBudgetEntryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateMonthlyBudgetEntry">>
  > {
    const statement = `subscription OnUpdateMonthlyBudgetEntry($filter: ModelSubscriptionMonthlyBudgetEntryFilterInput, $owner: String) {
        onUpdateMonthlyBudgetEntry(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteMonthlyBudgetEntryListener(
    filter?: ModelSubscriptionMonthlyBudgetEntryFilterInput,
    owner?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteMonthlyBudgetEntry">>
  > {
    const statement = `subscription OnDeleteMonthlyBudgetEntry($filter: ModelSubscriptionMonthlyBudgetEntryFilterInput, $owner: String) {
        onDeleteMonthlyBudgetEntry(filter: $filter, owner: $owner) {
          __typename
          owner
          username
          id
          budgetedAmountInCents
          categoryId
          category {
            __typename
            owner
            username
            id
            name
            description
            sortingPosition
            hidden
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          monthlyBudgetYearAndMonth
          categoryYearAndMonth
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }
}
