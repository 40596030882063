import { AlertController, Platform, ToastController} from "@ionic/angular";
import { Utils } from "./utils";
import { getCurrentUser } from 'aws-amplify/auth';
import {GlobalStores} from "./global-stores";
import { generateClient } from 'aws-amplify/api';

export class NodeController {
  private _entities: Array<any>;
  private _controllerReady: boolean = false;
  private _hiddenEntities: Array<any>;
  private _preloadingState: boolean;
  private readonly namedResource: boolean;
  public client: any;

  public static sortArray(arr: Array<any>, sortingField: string, sortingDirection: string): Array<any> {
    let parts: string[] = sortingField.split('.');
    if(parts.length > 2) {
      console.error('Cannot use more than two levels');
    }

    if (parts.length == 1) {
      if (sortingDirection == 'desc') {
        return arr.sort((a, b) => (a[sortingField] < b[sortingField]) ? 1 : -1);
      } else {
        return arr.sort((a, b) => (a[sortingField] > b[sortingField]) ? 1 : -1)
      }
    } else if (parts.length == 2) {
      if (sortingDirection == 'desc') {
        return arr.sort((a, b) => (a[parts[0]][parts[1]] < b[parts[0]][parts[1]]) ? 1 : -1);
      } else {
        return arr.sort((a, b) => (a[parts[0]][parts[1]] > b[parts[0]][parts[1]]) ? 1 : -1)
      }
    }
  }

  constructor(
    private isNamed: boolean,
    private nameField: string,
    private sortingField: string,
    private sortingDirection: string,
    private sortingLogic: (arr: Array<any>, sortingField, sortingDirection) => void,
    private platform: Platform,
    private toastController: ToastController,
    private alertController: AlertController,
    private onCreateListener: (filter?: any, owner?: string) => any,
    private extractCreateData: (evt) => any,
    private createMethod: (value) => Promise<any>,
    private onDeleteListener: (filter?: any, owner?: string) => any,
    private extractDeleteData: (evt) => any,
    private deleteMethod: (value) => Promise<any>,
    private onUpdateListener: (filter?: any, owner?: string) => any,
    private extractUpdateData: (evt) => any,
    private updateMethod: (value) => Promise<any>,
    private listEntities: (username? : string, id?: any, filter?: any, limit?: number, nextToken?: string, sortDirection?: any) => Promise<any>)
  {
    this.namedResource = isNamed;
    this.initializeTab();
  }

  // @ts-ignore
  get entities(): Array<any> {
    return this._entities;
  }

  // @ts-ignore
  get hiddenEntities(): Array<any> {
    return this._hiddenEntities;
  }

  // @ts-ignore
  get preloadingState(): boolean {
    return this._preloadingState;
  }

  getCurrentSortingField(): string {
    return this.sortingField;
  }

  getCurrentSortingDirection(): string {
    return this.sortingDirection;
  }

  getEntityById(id: string): any {
    let entity = this._entities.find(v => v.id == id);
    if (entity === undefined) {
      entity = this._hiddenEntities.find(v => v.id == id);
    }
    return entity;
  }

  getEntityByName(name: string): any {
    let entity = this._entities.find(v => v.name == name);
    if (entity === undefined) {
      entity = this._hiddenEntities.find(v => v.name == name);
    }
    return entity;
  }

  sortBy(field, direction) {
    this.sortingField = field;
    this.sortingDirection = direction;

    this.sortEntities(this.entities);
  }

  sortEntities(entitiesToSort: Array<any>): Array<any> {
    this.sortingLogic(entitiesToSort, this.sortingField, this.sortingDirection);
    return entitiesToSort;
  }

  async waitControllerReady() {
    while (!this._controllerReady) {
      console.log('waiting for the controller to be ready...');
      await new Promise(resolve => setTimeout(resolve, 250));
    }
    console.log('The controller is now ready');
  }

  async waitForEntity(name: string) {
    if (!this.isNamed) {
      throw "Cannot use waitForEntity method on non-named resources";
    }

    let allEntities = [...this._entities, ...this._hiddenEntities];
    while (allEntities.find(v => v['name'] === name) === undefined) {
      console.log('waiting for entity: ' + name + ';' + ' found: ' + allEntities);
      await new Promise(resolve => setTimeout(resolve, 250));
      allEntities = [...this._entities, ...this._hiddenEntities];
    }
  }

  initializeTab() {
    this._entities = [];
    this._hiddenEntities = [];
    this._preloadingState = true;
    this.client = generateClient();

    console.log('<<<<<<<<<<<<');
    console.log(this.client);
    console.log('>>>>>>>>>>>>')

    const that = this;

    this.platform.ready().then(() => {
      getCurrentUser().then(({ username, userId, signInDetails }) => {
        console.log('Logged in as ' + username);

        function getAllData() {
          function recursiveCall(token?: string | null) {
            console.log('Pulling one page of data.');
            ((token) ? that.listEntities(username, undefined, undefined, undefined, token): that.listEntities(username)).then((evt) => {
              that._entities = [...that._entities, ...(evt.items.filter((el) => (el._deleted !== true && el.hidden !== true)))];
              that._hiddenEntities = [...that._hiddenEntities, ...evt.items.filter((el) => el.hidden === true && el._deleted !== true)]; // ignore deleted entirely;
              if (evt.nextToken) {
                console.log('There is another page to pull...');
                recursiveCall(evt.nextToken);
              } else {
                console.log('Done pulling data');
                that._controllerReady = true;
              }
            }).then(() => {

              that._entities = that.sortEntities(that._entities);
              that._preloadingState = false;
              GlobalStores.lastTouched = Date.now();

              console.log('Initialized entities post user authentication');
            });
          }

          recursiveCall(null);
        }

        console.log('Pulling all data locally...');
        getAllData();

        this.onCreateListener({owner: username}).subscribe((evt) => {
          const data = this.extractCreateData(evt);
          if (data._deleted !== true && data.hidden !== true) {
            this._entities = this.sortEntities([...this._entities, data]);
          } else if (data._deleted !== true && data.hidden === true) {
            this._hiddenEntities = [...this._hiddenEntities, data];
          }

          GlobalStores.lastTouched = Date.now();

          console.log('Entity created remotely and synched locally.');
        });

        this.onDeleteListener({owner: username}).subscribe((evt) => {
          const data = this.extractDeleteData(evt);

          const index = this._entities.findIndex(element => element.id === data.id);
          if (index !== -1) {
            this._entities.splice(index, 1);
          }

          const hiddenIndex = this._hiddenEntities.findIndex(element => element.id === data.id);
          if (hiddenIndex !== -1) {
            this._hiddenEntities.splice(hiddenIndex, 1);
          }

          GlobalStores.lastTouched = Date.now();

          console.log('Entity removed remotely and synched locally.');
        });

        this.onUpdateListener({owner: username}).subscribe((evt) => {
          const data = this.extractUpdateData(evt);
          const index = this._entities.findIndex(element => element.id === data.id);
          let justHidden: boolean;
          // find it in the categories and update it
          if (index !== -1) {
            this._entities[index] = data;
            // if it was just marked as hidden, removing it from the list of categories and adding it to the list of hidden categories.
            if (data.hidden) {
              this._entities.splice(index, 1);
              this._hiddenEntities = [...this._hiddenEntities, data];
              justHidden = true;
            }
          }
          const hiddenIndex = this._hiddenEntities.findIndex(element => element.id === data.id);
          // if we didn't ust hide it, and we find it in the list of hidden categories and it shouldn't be hidden anymore, we move it to the categories list.
          if (!justHidden && hiddenIndex !== -1 && !data.hidden) {
            this._hiddenEntities.splice(hiddenIndex, 1);
            this._entities = this.sortEntities([...this._entities, data]);
          }

          GlobalStores.lastTouched = Date.now();

          console.log('Entity updated remotely and synched locally.');
        });

        GlobalStores.lastTouched = Date.now(); // final update to the cache given we just registered the listeners
      }, error => { console.log(error)} );
    });
  }

  createEntity(value: any, readableName: string, errorMessageAlreadyExists: string, errorMessageGeneral: string) {
    if (this._entities != null && this.namedResource) {
      const index = this._entities.findIndex(element => element[this.nameField] === readableName);
      if (index != -1) {
        Utils.presentToast(this.toastController, errorMessageAlreadyExists, 'danger');
        return;
      }
    }

    if (this._hiddenEntities != null && this.namedResource) {
      const entity = this._hiddenEntities.find(element => element[this.nameField] === readableName);
      if (entity != null) {
        console.log('A deleted version of the same entity already exists. Reviving instead.');

        let updateValue = value;
        updateValue.id = entity.id;
        updateValue.hidden = false;
        updateValue._version = entity._version;

        this.updateEntity(updateValue, entity.id, readableName, errorMessageAlreadyExists, errorMessageGeneral);
        return;
      }
    }

    getCurrentUser().then(({ username, userId, signInDetails }) => {
      value['username'] = username;
      this.createMethod(value).then(success => {
          GlobalStores.lastTouched = Date.now();
          console.log('Entity ' + readableName + ' successfully created');
        }, error => {
          console.log('Failed ' + readableName + ' inserting a new entity.');
          console.log(error);
          console.log(value);
          Utils.presentToast(this.toastController, errorMessageGeneral, 'danger');
        }
      );
    });
  }

  private checkEntitiesForUpdate(entitiesToCheck, name, id) {
    return this.namedResource && entitiesToCheck != null && entitiesToCheck.findIndex(element => element[this.nameField] === name && element.id !== id) != -1;
  }

  updateEntity(value: any, id: string, readableName: string, errorMessageAlreadyExist: string, errorMessageGeneral: string) {
    if (this.checkEntitiesForUpdate(this._entities, readableName, id) || this.checkEntitiesForUpdate(this._hiddenEntities, readableName, id)) {
      if (!Utils.nullOrEmpty(errorMessageAlreadyExist)) Utils.presentToast(this.toastController, errorMessageAlreadyExist, 'danger');
      return;
    }

    getCurrentUser().then(({ username, userId, signInDetails }) => {
      value['username'] = username;
      this.updateMethod(value).then(success => {
        GlobalStores.lastTouched = Date.now();
      }, error => {
        console.log(errorMessageGeneral);
        console.log(error);
        if (!Utils.nullOrEmpty(errorMessageGeneral)) Utils.presentToast(this.toastController, errorMessageGeneral, 'danger');
      });
    });
  }

  forceHardDeleteEntity(objId) {
    getCurrentUser().then(({ username, userId, signInDetails }) => {
      objId['username'] = username;
      this.deleteMethod(objId).then(success => {
        console.log('Successfully removed (hard-deleted). Id: ' + objId.id);
        GlobalStores.lastTouched = Date.now();
      }, error => {
        console.log('Failed removing the object. Id: ' + objId.id);
        console.log(error);
      }).finally(() => GlobalStores.lastTouched = Date.now());
    });
  }

  deleteEntity(id: string, version: number, alertMessage: string, toastErrorMessage: string, hardDelete: boolean = false) {
    this.deleteEntityAsync({ 'id': id, '_version': version }, alertMessage).then(success => {
      GlobalStores.lastTouched = Date.now();
    }, error =>  {
      Utils.presentToast(this.toastController, toastErrorMessage, 'danger');
    });
  }

  deleteEntityByObjId(objId: any, alertMessage: string, toastErrorMessage: string, hardDelete: boolean = false) {
    this.deleteEntityAsync(objId, alertMessage, hardDelete).then(success => {
      GlobalStores.lastTouched = Date.now();
    }, error =>  {
      Utils.presentToast(this.toastController, toastErrorMessage, 'danger');
    });
  }

  async deleteEntityAsync(objId: any, alertMessage: string, hardDelete: boolean = false) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'You sure?',
      message: alertMessage,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (value) => {
            console.log('Confirm Cancel.');
          }
        }, {
          text: 'Yes, delete it!',
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
            if (!hardDelete) {
              const newValue = objId;
              newValue.hidden = true;
              this.updateMethod(newValue).then(success => {
                console.log('Successfully removed (soft-deleted). Id: ' + objId.id);
                GlobalStores.lastTouched = Date.now();
              }, error => {
                console.log('Failed removing the. Id: ' + objId.id);
                console.log(error);
              });
            } else {
              this.forceHardDeleteEntity(objId);
            }
          }
        }
      ]
    });

    await alert.present();
  }
}
